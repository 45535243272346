import React from 'react';
import { makeStyles } from '@material-ui/core';

/*
 * This is the theme for Globant. It is based on the brand definiton.
 * You can find more information about the brand definition in the https://brand.globant.com/
 */
const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path1: {
    fill: '#fff',
  },
  path2: {
    fill: '#bfd732',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      id='Layer_1'
      data-name='Layer 1' 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 598 174"
      >
        <path 
          className={classes.path1} 
          d="M75.39,129.83c-26.3,0-42.47-22.08-42.46-46,0-25.29,14.47-45.55,42.21-45.54,20.39,0,36.13,
          12.15,37.42,29.31H94.49c-.9-8-9.54-14.45-19.35-14.46-14.33,0-23.88,11.09-23.89,31.48,0,14.07,
          7.09,30.08,23.74,30.08,15.54,0,20.91-7.63,20.91-19.61h-19V80.79h35.74V95.19C112.67,115.57,104,
          129.84,75.39,129.83Z" />
        <path className={classes.path1} d="M123,126.88l0-86.72h16.78l0,86.72Z" />
        <path className={classes.path1} 
          d="M147.74,90.19c1.37-14.15,13.91-27,28.06-28.26,22.4-2,36.61,11.53,
          36.6,33.23,0,20.13-12.53,33.42-32.27,33.41C157.94,128.57,145.56,112.56,147.74,90.19Zm47.76,
          5c0-12.9-5.16-19.23-15.35-19.23s-15.36,6.32-15.37,19.22,5.16,19.36,15.35,19.37S195.49,108.06,
          195.5,95.15Z" />
        <path className={classes.path1} 
          d="M220.4,126.91l0-86.72h16.52V71.68c4.78-6.71,11-9.93,18.85-9.93,16.39,0,26.06,13.56,26,32.79,0,
          21.42-11.49,34.06-27.49,34.06-7.75,0-13.68-3-17.68-9.43l-.13,7.74Zm44.4-31.22c0-13-4.64-19.61-14.45-19.62-9.29,
          0-14.07,6.84-14.07,20.65,0,10.71,6.19,18.2,14.19,18.2C258.86,114.92,264.8,107.05,264.8,95.69Z" />
        <path className={classes.path1} 
          d="M331.54,127a25.19,25.19,0,0,1-1-5.68c-4,4.38-10.71,7.35-19.23,7.35-14.45,0-21.16-7.11-21.16-17.43,
          0-18.33,12.27-20.39,29.17-22.71,8.39-1.15,10.72-2.83,10.72-7.22,0-4.13-4.13-6.58-10.71-6.58-7.61,
          0-10.71,3.73-11.49,9.41H292.19c.27-13.17,7.5-22.33,27.89-22.32,20.13,0,27.1,9,27.09,25V127Zm-1.15-31.11c-1.68,
          1.68-4.65,2.58-10.84,3.74-9.55,1.81-12.65,4.52-12.65,9.94,0,4.77,2.84,7.09,8.13,7.1,8.64,0,15.1-6.32,
          15.23-13.81Z" />
        <path className={classes.path1} 
          d="M399.28,127V88.51c0-9.81-2.83-12.52-10.84-12.52-8.77,0-13,4.9-13,14.58V127H358.63l0-63.49h16v9.29c3.87-7.1,
          10.32-11,20.52-11,12.13,0,21,7.36,21,21.17l0,44Z" />
        <path className={classes.path1} 
          d="M464.39,103.18c-.37,7-3.48,10.95-9.78,10.95-6.84,0-9.67-4.65-9.67-12.65V75.41h26V61.86H445V46.37H428.44l0,
          56.79c0,13.68,7.35,25.56,25.93,25.57,17.94,0,25.69-12,25.69-25.42v-.12Z" />
        <path className={classes.path2} 
          d="M500.24,35.53A3.92,3.92,0,0,0,494.51,40L502,51.17a64.82,64.82,0,0,1,0,72.11l-7.48,11.16a3.92,
          3.92,0,0,0,5.72,4.48l62.71-47.77a4.91,4.91,0,0,0,0-7.82Z" />
    </svg>
  );
};

export default LogoFull;
