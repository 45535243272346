import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  githubAuthApiRef,
  createApiFactory,
  identityApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { CustomTechRadarApi } from './components/techRadar/customTechRadarApi';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { visitsApiRef, VisitsWebStorageApi } from '@backstage/plugin-home';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) => ScmAuth.forGithub(githubAuthApi),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(techRadarApiRef, new CustomTechRadarApi()),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef
    },
    factory: ({ identityApi, errorApi }) => VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
  /*ScmAuth.createDefaultApiFactory(),*/
];
