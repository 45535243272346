import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
} from '@backstage/theme';

/*
 * This is the theme for Globant. It is based on the brand definiton.
 * You can find more information about the brand definition in the https://brand.globant.com/
 */
const globantBackground = '#03081B'
const globantBackground2 = '#121212'
const globantPrincipal = '#BFD732';
/* const globantSecondary = '#38EFA0'; */
const white = '#FFFFFF';
const black = '#000000';
const globantFontFamily = 'Hebo, Roboto, sans-serif';
const defaultPageThemeName = 'home';

export const globantTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.light,
            navigation: {
                background: globantBackground,
                indicator: globantPrincipal,
                color: white,
                selectedColor: globantPrincipal,
                submenu: {
                    background: globantBackground2,
                }
            },
        },
    }),
    fontFamily: globantFontFamily,
    defaultPageTheme: defaultPageThemeName,
    pageTheme: {
        home: genPageTheme({ colors: [], shape: shapes.color, options: { fontColor: black } })
    },
    components: {
        BackstageHeader: {
            styleOverrides: {
                header: ({ theme }) => ({
                    backgroundColor: theme.palette.navigation.background,
                }),
                title: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                subtitle: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                type: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                leftItemsBox: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                rightItemsBox: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
            },
        },
        BackstageHeaderLabel: {
            styleOverrides: {
                label: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                value: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                ol: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
                separator: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeInherit: ({ theme }) => ({
                    color: theme.palette.navigation.indicator,
                }),
            },
        },
        PluginCatalogEntityContextMenu: {
            styleOverrides: {
                button: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.navigation.color,
                }),
            }
        }
    }
});