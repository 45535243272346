import React from 'react';
import { makeStyles } from '@material-ui/core';

/*
 * This is the theme for Globant. It is based on the brand definiton.
 * You can find more information about the brand definition in the https://brand.globant.com/
 */
const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 48,
    enableBackground: 'new 0 0 174 174',
  },
  path1:{
    fill: '#FFFFFF',
  },
	path2:{
    fill: '#BFD732',
  }
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg 
      className={classes.svg}
      version="1.1" 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg" 
      x="0px" 
      y="0px"
      viewBox="0 0 174 174" 
      xmlSpace='preserve'>
      <g>
	      <path className={classes.path1} 
          d="M59.2,121.6c-19.9,0-32.1-16.7-32.1-34.8C27.1,67.7,38,52.4,59,52.4c15.4,0,27.3,9.2,28.3,22.2H73.6
		      c-0.7-6.1-7.2-10.9-14.6-10.9c-10.8,0-18.1,8.4-18.1,23.8c0,10.6,5.4,22.8,18,22.8c11.8,0,15.8-5.8,15.8-14.8H60.3V84.5h27v10.9
		      C87.4,110.8,80.8,121.6,59.2,121.6z"/>
	      <path className={classes.path2}
          d="M98,47.9c-1.4-0.8-3.2-0.3-4.1,1.1c-0.4,0.7-0.5,1.5-0.3,2.3l5.7,8.4c11,16.5,11,38,0,54.5l-5.7,8.4
		      c-0.5,1.6,0.5,3.2,2,3.7c0.8,0.2,1.6,0.1,2.3-0.3L145.5,90c1.6-1.2,2-3.6,0.7-5.2c-0.2-0.3-0.4-0.5-0.7-0.7L98,47.9z"/>
      </g>
    </svg>
  );
};

export default LogoIcon;
