import React from 'react';
import {
  Content,
  Header,
} from '@backstage/core-components';
import { Page } from '@backstage/core-components';
import { ClockConfig, HeaderWorldClock, HomePageRecentlyVisited, HomePageTopVisited, WelcomeTitle } from '@backstage/plugin-home';
import { Grid, Typography } from '@material-ui/core';
import { HomePageSearchBar } from '@backstage/plugin-search';

const languages = ['English', 'Spanish'];
const headerSubtitle = 'Welcome to your home in Backstage';
const headerTitleOverride = 'Home';

const clockConfigs: ClockConfig[] = [{
  label: 'ARG',
  timeZone: 'America/Argentina/Buenos_Aires'
}, {
  label: 'UTC',
  timeZone: 'UTC'
}, {
  label: 'NYC',
  timeZone: 'America/New_York'
}];
const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
};

export const HomePage = () => (
  <Page themeId="home">
    <Header title={<WelcomeTitle language={languages} />} pageTitleOverride={headerTitleOverride} subtitle={headerSubtitle}>
      <HeaderWorldClock clockConfigs={clockConfigs} customTimeFormat={timeFormat} /> 
    </Header>
    <Content>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9} xl={9} alignContent='center'>
          <Typography variant="h3">Jump back in</Typography>
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <HomePageSearchBar placeholder="Search" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={6}>
          <HomePageRecentlyVisited />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <HomePageTopVisited />
        </Grid>
      </Grid>
    </Content>
  </Page>
);